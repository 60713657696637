<template>
<a-card :bordered="false">
  <div class="realtor">
    <!-- 查询表单 -->
    <a-form-model ref="searchForm" layout="horizontal" :label-col="{ xs: 6 }" :wrapper-col="{ xs: 18 }">
      <a-row :gutter="8">
        <a-col :md="8" :xl="6" :xxl="6" :sm="12">
          <a-form-model-item label="经销商名称">
            <a-input v-model="searchData.name" placeholder="请输入经销商名称" allowClear></a-input>
          </a-form-model-item>
        </a-col>
        <a-col :md="8" :xl="6" :xxl="6" :sm="12">
          <a-form-model-item label="大区事务所">
            <a-cascader
              :options="dealerList"
              v-model="regionalOffice"
              change-on-select
              placeholder="请选择大区/事务所"
              @change="onChange"
              :field-names="{ label: 'title', value: 'id', children: 'children' }"
            />
          </a-form-model-item>
        </a-col>
        <a-col :md="8" :xl="6" :xxl="6" :sm="12">
            <a-form-model-item label="经销商类型">
              <DictSelect field="dealerType" :value.sync="searchData.dealerType" style="width: 100%" placeholder="订单状态"></DictSelect>
            </a-form-model-item>
          </a-col>
          <a-col :md="8" :xl="6" :xxl="6" :sm="12">
            <a-form-model-item label="合作状态">
              <DictSelect field="cooperateStatus" :value.sync="searchData.cooperateStatus" style="width: 100%" placeholder="生成方式"></DictSelect>
            </a-form-model-item>
          </a-col>
        <a-col :md="8" :xl="6" :xxl="6" :sm="12">
          <a-form-model-item label="帐号">
            <a-input v-model="searchData.account" placeholder="请输入帐号" allowClear></a-input>
          </a-form-model-item>
        </a-col>
        <a-col :xxl="6" :xl="5" :md="8" sm="2">
          <a-form-model-item :label-col="{ span: 0 }" :wrapper-col="{ span: 24 }">
            <a-button type="primary" @click="getDataFilter()" icon="search">查询</a-button>
            <a-button type="default" @click="reset()" icon="sync">重置</a-button>
          </a-form-model-item>
        </a-col>
      </a-row>
    </a-form-model>
    <div>
      <!-- <a-button type="primary" @click="toHandler('add')" icon="plus" v-if="isCusInfoAdd">新增</a-button> -->
      <a-button type="primary" v-if="selectedRowKeys.length >= 1 && isCusInfoEdit"  @click="toHandleEait" icon="edit">编辑</a-button>
      <!-- <a-button type="primary" v-if="selectedRowKeys.length >= 1 && isCusInfoDel" @click="toHandler('del')" icon="delete">删除</a-button> -->
      <a-button type="primary" v-if="selectedRowKeys.length >= 1 && isCusInfoSelect" @click="toHandler('check')" icon="copy">查看</a-button>
      <!-- <a-button type="default" v-if="selectedRowKeys.length >= 1 && isCusInfoPwd" @click="onPassword()">重置密码</a-button> -->
      <!-- <a-button type="default" v-if="selectedRowKeys.length >= 1 && isUpdateContact" @click="toHandler('contact')">修改联系人</a-button> -->
      <!-- <a-button type="default" v-if="selectedRowKeys.length >= 1 && isUpdateAddress" @click="toHandler('address')">修改收货地址</a-button> -->
      <!-- <a-button type="default" v-if="selectedRowKeys.length >= 1 && isInvoice" @click="toHandler('invoice')">修改发票</a-button> -->
    </div>
    <!-- 数据表格 -->
    <a-table
      :loading="tableLoading"
      :row-selection="{type: 'radio', selectedRowKeys: selectedRowKeys, onChange: onSelectChange }"
      :rowKey="(record) => record.id"
      :columns="columns"
      :data-source="tableData"
      bordered
      :pagination="page"
      @change="changeTable"
      :rowClassName="rowClassName">
      <span slot="dealerType" slot-scope="text">
        <a-tag v-if="text == 1">中心店</a-tag>
        <a-tag v-if="text == 2">核心店</a-tag>
        <a-tag v-if="text == 3">一般店</a-tag>
        <a-tag v-if="text == 4">核心店（预备）</a-tag>
      </span>
      <span slot="dealerClassify" slot-scope="text">
        <a-tag color="purple" v-if="text == 1">订单经销商</a-tag>
        <a-tag color="pink" v-if="text == 2">报备经销商</a-tag>
        <a-tag color="pink" v-if="text == 3">报备经销商</a-tag>
      </span>
      <span slot="verifyStatus" slot-scope="text">
          <a-tag v-if="text == 0" color="#333333">草稿</a-tag>
          <a-tag v-if="text == 1" color="#e6a23c">待审核</a-tag>
          <a-tag v-if="text == 2" color="#108ee9">审核通过</a-tag>
          <a-tag v-if="text == 3" color="#67c23a">审核拒绝</a-tag>
      </span>
      <span slot="cooperateStatus" slot-scope="text">
        <a-tag v-if="text == 1" color="#87d068">合作中</a-tag>
        <a-tag v-if="text == 2">合作终止</a-tag>
      </span>
      <span slot="shareFirmList" slot-scope="text">
        <label v-if="text == null">未共享</label>
        <!--        <a-tooltip placement="left" title="getFirmInfo" :get-popup-container="getPopupContainer">-->
          <label v-if="text != null">
            <label style="color: red" v-bind:key="item.firmName" v-for="item in text" >【{{item.areaName}}】{{item.firmName}} <br/></label>
          </label>
        <!--        </a-tooltip>-->
      </span>

    </a-table>
    <!-- 编辑表单 -->
    <CustomerInfo-edit-modal ref="CustomerInfoEditModal" @reload="getData"></CustomerInfo-edit-modal>
    <CustomerInfoCheckModal ref="CustomerInfoCheckModal" @reload="getData"></CustomerInfoCheckModal>
      <!--查看-->
    <!-- <Password ref="password"></Password> -->
     <a-modal v-model="visibleEait" title="编辑经销商类型" @ok="handleOk" @cancel="handleCancel" :maskClosable="false">
       <a-form-model>
        <a-form-model-item label="经销商类型" >
    <a-radio-group v-model="value" >
      <a-radio :value="item.id" v-for="item in dealerType" :key="item.id">{{item.label}}</a-radio>
    </a-radio-group>
    </a-form-model-item >
     </a-form-model>
    </a-modal>
  </div>
</a-card>

</template>

<script>
// import Password from './components/password'
import { columns } from './components/colums.js'
import CustomerInfoEditModal from './components/CustomerInfoEditModal.vue'
import CustomerInfoCheckModal from './components/CustomerInfoCheckModal.vue'
import { delCustomerInfo, listCustomerInfo, selectByIdCustomerInfo } from './api/CustomerInfoApi'
import { checkPermission } from '@/utils/permissions'

const pageSource = {
  current: 1,
  pageSize: 10,
  total: 0,
}
export default {
  name: 'customerType',
  components: {
    CustomerInfoEditModal,
    // Password,
    CustomerInfoCheckModal
  },
  data() {
    return {
     visibleEait: false,
      columns: columns,
      tableData: [],
      searchData: {}, // 搜索条件
      scroll: { y: 600 },
      tableLoading: false, //表格loading
      value: 1,
      dealerType:[
        {id:1,value:1,label:"中心店"},
        {id:2,value:2,label:"核心店"},
        {id:3,value:3,label:"一般店"},
        {id:4,value:4,label:"核心店（预备）"},
      ],
      page: {
                current: 1,
                pageSize: 10,
                total: 0,
                showSizeChanger: true,
                showTotal: () => `共${this.page.total}条`,
            },
      selectedRowKeys: [],
      selectedRows: [],
      statusList: [],
      item: [],
      isCusInfoEdit: checkPermission('cus:info:edit'),
      isCusInfoSelect: checkPermission('cus:info:select'),
      status: [
        {
          key: ' ',
          label: '全部',
        },
        {
          key: true,
          label: '正常',
        },
        {
          key: false,
          label: '冻结',
        },
      ],
      dealerList: [],
      regionalOffice: []
    }
  },
  computed: {
    rowSelection() {
      const _this = this
      return {
        fixed: true,
        type: 'radio', //"radio"
        selectedRowKeys: this.selectedRowKeys,
        onChange: (selectedRowKeys, selectedRows) => {
          _this.selectedRowKeys = selectedRowKeys
          _this.selectedRows = selectedRows
        },
      }
    },
    // custoStatus() {
    //   if(this.statusList.length > 0) {
    //     return this.statusList.array.every(value => {
    //       return value != this.statusList[0]
    //     })
    //   }else {
    //     return true
    //   }
    // }
  },
  methods: {
    //   编辑弹框
    toHandleEait(){
      this.value = this.dealerType[0].id
       this.visibleEait = true
      },
    // 取消弹框
    handleCancel(){
      this.visibleEait = false
    },
    handleOk(){

      if(this.value == ''){
        this.$notification.warning({
          message: '请先选择经销商类型',
        })
      }
      let params ={
          dealerType:this.value
      }
      params.dealerId =  parseInt(this.item[0].id),
     this.axios.post('/api/dealer/dealer/dealerInfo/updateDealerType',params).then(res => {
        if (res.code == 200) {
            this.$message.success(res.message)
            this.visibleEait = false
            this.getData()
          } else {
            this.$message.error(res.message)
          }
     })

    },
    // 隔行换色
        rowClassName(record,index) {
            let className = "light-row"
            if (index % 2 === 1) className = "dark-row"
            return className;
        },
    /**
     * 获取表格数据
     */
    getData() {
      this.selectedRows = []
      this.selectedRowKeys = []
      this.tableLoading = true
      listCustomerInfo({
        pageNumber: this.page.current,
        pageSize: this.page.pageSize,
        ...this.searchData,
      })
        .then((res) => {
          if (res.code === 200) {
            const { total, records } = res.body
            this.page.total = total
            this.tableData = records
          }
        })
        .finally(() => (this.tableLoading = false))

        this.axios.post('/api/firm/mhiac/mhiacFirm/getTree').then((res) => {
          // 获取大区事务所经销商数据
          this.dealerList = res.body
        })
    },
    onSelectChange(selectedRowKeys, selectedRows) {
      this.selectedRowKeys = selectedRowKeys
      this.item = selectedRows
    },
    /**
     * 筛选查询
     */
    getDataFilter() {
      this.page.current = 1;
      this.getData()
    },

    onChange(val) {
      this.searchData.bigAreaId = val[0]
      this.searchData.firmId = val[1]
    },
    /**
     * 重置
     */
    reset() {
      this.page.current = 1;
      this.page.pageSize = 10;
      this.searchData = {}
      this.regionalOffice = []
      this.getData()
    },
    /**
     * 更改分页
     */
    changeTable(pagination) {
      this.page = pagination
      this.getData()
    },
    handleChange(value) {},
    /**
     * 点击行选中
     */
    changeTableRow(record) {
      return {
        on: {
          click: (e) => {
            if (this.selectedRowKeys[0] === record.id) {
              this.selectedRowKeys = []
              this.selectedRows = []
            } else {
              this.selectedRowKeys = [record.id]
              this.selectedRows = [record]
            }
          },
        },
      }
    },
    onPassword() {
      const _this = this
      if (_this.item.length === 0) {
        _this.$notification.warning({
          message: '请选择一条记录',
        })
        return
      }
      _this.$refs.password.onsetRowData(_this.item[0])
    },
    toHandler(name) {
      const _this = this
      if (name === 'add') {
        _this.$refs.CustomerInfoEditModal.setRowData({}, 'add')
        return
      }
      if (_this.item.length === 0) {
        _this.$notification.warning({
          message: '请选择一条记录',
        })
        return
      }
      switch (name) {
        case 'edit':
          _this.$refs.CustomerInfoEditModal.setRowData(_this.item[0], 'edit')

          break
        case 'del':
          _this.$confirm({
            title: '删除警告',
            content: '确认要删除此条记录吗?',
            okText: '确认',
            cancelText: '取消',
            async onOk() {
              const res = await delCustomerInfo(_this.item[0].id)
              if (res.code === 200) {
                _this.$notification.success({ message: res.message })
                _this.getData()
              } else {
                _this.$notification.error({ message: res.message })
              }
            },
            onCancel() {},
          })
          break
        case 'check':
          _this.$refs.CustomerInfoCheckModal.setRowData(_this.item[0], 'check')
          break
        case 'contact':
          _this.$refs.CustomerInfoEditModal.setRowData(_this.item[0], 'edit', '2')
          break;
        case 'address':
          _this.$refs.CustomerInfoEditModal.setRowData(_this.item[0], 'edit', '3')
          break;
        case 'invoice':
          _this.$refs.CustomerInfoEditModal.setRowData(_this.item[0], 'edit', '4')
          break;
      }
    },
  },
  created() {
    this.getData()
  },
}
</script>

<style lang="less" scoped>
.ant-btn {
  margin-right: 8px;
  margin-bottom: 12px;
}
::v-deep .ant-table-tbody > .light-row {
        background: #FFF!important;;
    }
    ::v-deep .ant-table-tbody > .dark-row {
        background: #fafafa!important;
    }
</style>